import { Routes, RouterModule } from '@angular/router';
import { ResultadoImagenesDiagnosticasRoutingModule } from './resultado-imagenes-diagnosticas/resultado-imagenes-diagnosticas.routing';

const routes: Routes = [

  //Resultados Imagenes Diagnosticas  
  //ResultadosImagenesDiagnosticasRoutesModule.RoutingResultadosImagenesDiagnosticasRoutes,

  //resultado imagenes diagnosticas
  ResultadoImagenesDiagnosticasRoutingModule.RoutingResultadoImagenesDiagnosticas,
  
];

export const ImgDiagnosticasRoutes = RouterModule.forChild(routes);