<div class="content">
    <div>
        <img class="logo"
            src="/assets/images/seguridad.png">
    </div>
    <div class="error-info">
        <div class="title">
            <font style="vertical-align: inherit;">
                <font style="vertical-align: inherit;">401 - No tienes acceso.</font>
            </font>
        </div>
        <div class="details">
            <font style="vertical-align: inherit;">
                <font style="vertical-align: inherit;">Su cuenta, {{usuario}} ({{name}}) no está autorizada para ver
                    esta página. <span style="color: goldenrod;">{{url}}</span> </font>
                <font style="vertical-align: inherit;"> Asegúrese de que la URL sea correcta y que su cuenta tenga
                    acceso. </font>

            </font> 
            <div class="reference-data">
               
                <div *ngFor="let item of perfiles">
                    <font style="vertical-align: inherit;">
                        <font style="vertical-align: inherit;"> <i class="fa-solid fa-award" style="color: #00bcd4;"></i> {{item | titlecase}}</font>
                    </font>
                </div>
             
            </div>
            <font style="vertical-align: inherit;"> 
                <font style="vertical-align: inherit;">Puede solicitar acceso poniéndose en contacto con el
                    administrador.</font>
            </font>
        </div>
    
        <a class="secondary-action" (click)="logout()" href="javascript:;">
            <font style="vertical-align: inherit;">
                <font style="vertical-align: inherit;">Cerrar sesión e iniciar sesión con una cuenta diferente</font>
            </font>
        </a>
    </div>
</div>