// Angular Core and Platform
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// Environment
import { environment } from '../environments/environment';

// Services
import { AuthGuard } from './services/Security/guard.service';
import { AuthenticationService } from './services/Security/authentication.service';
import { PersistenceService } from './services/Utilities/data.persistence.service';
import { UtilService } from './services/Utilities/util.service';
import { HomeService } from './pages/home/services/home.service';
import { SettingsService } from './services/Utilities/settings.service';
import { ThemeService } from './services/Utilities/theme.service';

// HTTP and Interceptors
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { JwtInterceptor } from './helpers/jwt.interceptor';
import { BlobErrorHttpInterceptor } from './helpers/blob.interceptor';

// Localization
import { registerLocaleData } from '@angular/common';
import localeCo from '@angular/common/locales/es-CO';

// Forms and Reactive Forms
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// Components
import { LoginComponent } from './pages/login/login.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { RecoveryPasswordComponent } from './pages/recovery-password/recovery-password.component';
import { PagosPendientesComponent } from './pages/pagos-pendientes/pagos-pendientes.component';
import { NoPermisosComponent } from './pages/no-permisos/no-permisos.component';
import { HelloWorldContainer } from './core/dashboard/components/hello-world/hello-world.container';
import { HelloWorldComponent } from './core/dashboard/components/hello-world/hello-world.component';

// Pipes
import { NormalizeMenuPipe } from '@src/pipes/normalize-menu.pipe';
import { MainPipe } from '@src/pipes/main-pipe.module';

// Kendo UI Modules
import { GridModule } from '@progress/kendo-angular-grid';
import { IntlModule } from "@progress/kendo-angular-intl";
import { IndicatorsModule } from '@progress/kendo-angular-indicators';
import { NavigationModule } from "@progress/kendo-angular-navigation";
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { LayoutModule } from "@progress/kendo-angular-layout";
import { TooltipsModule } from '@progress/kendo-angular-tooltip';
import { TreeViewModule } from '@progress/kendo-angular-treeview';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import "@progress/kendo-angular-intl/locales/es/all";
import "@progress/kendo-angular-intl/locales/es/calendar"

// Custom Modules
import { ComponentsCoreFormsModule, DirectivesCoreFormsModule, ComponentsCoreImportModule } from '@app/components/Core/core.saludplus.module';
import { HeaderModule } from './shared/components/header/header.module';
import { ConfigModule } from './services/Utilities/settings.service';

// Logging
import { LoggerModule, NgxLoggerLevel, TOKEN_LOGGER_SERVER_SERVICE } from 'ngx-logger';
import { AuthTokenServerService } from './helpers/AuthTokenServerService';

//security
import { SplusLogger } from './services/Utilities/splus.logger.service';


registerLocaleData(localeCo);



// Definir una constante con los módulos de Kendo
const KENDO_MODULES = [
    IntlModule,
    IndicatorsModule,
    NavigationModule,
    ButtonsModule,
    LayoutModule,
    TooltipsModule,
    TreeViewModule,        
    DialogsModule,
    GridModule,
];

//logger
const LOGGER_MODULE = LoggerModule.forRoot({
    serverLoggingUrl: 'Api/Logs',
    level: NgxLoggerLevel.WARN,
    serverLogLevel: NgxLoggerLevel.ERROR,
    disableConsoleLogging: false
}, {
    serverProvider: {
        provide: TOKEN_LOGGER_SERVER_SERVICE, useClass: AuthTokenServerService
    }
});

@NgModule({ declarations: [
        AppComponent,
        LoginComponent,
        PageNotFoundComponent,
        PagosPendientesComponent,
        NormalizeMenuPipe,
        RecoveryPasswordComponent,
        HelloWorldComponent, HelloWorldContainer, NoPermisosComponent
    ],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA
    ],
    bootstrap: [AppComponent], imports: [BrowserModule,
        AppRoutingModule,
        ReactiveFormsModule,
        FormsModule,
        BrowserAnimationsModule,
        HeaderModule,
        DirectivesCoreFormsModule,
        ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }), ComponentsCoreImportModule,
        ComponentsCoreFormsModule,
        //pipes modulos
        MainPipe,
        //kendo modulos
        ...KENDO_MODULES,
        //logger modulos
        LOGGER_MODULE], providers: [
        AuthGuard,
        SettingsService,
        ConfigModule.init(),
        AuthenticationService,
        PersistenceService,
        SplusLogger,
        NormalizeMenuPipe,
        UtilService,
        HomeService,
        { provide: LOCALE_ID, useValue: "es-CO" },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: JwtInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: BlobErrorHttpInterceptor,
            multi: true
        },
        ThemeService,
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class AppModule { }
