<div class="content">
    <div>
        <img class="logo"
            src="/assets/images/fallo.png">
    </div>
    <div class="error-info">
        <div class="title">
            <font style="vertical-align: inherit;">
                <font style="vertical-align: inherit;">404 - Servicio Suspendido.</font>
            </font>
        </div>
        <div class="details">
            <font style="vertical-align: inherit;">
                Estimado cliente, <br /><br />

            Lamentamos informarle que su servicio ha sido suspendido debido a la falta de pago. <br />
            Queremos asegurarle que tomamos esta decisión con gran pesar y después de agotar todas las vías de
            comunicación para recordarle sobre su deuda y ofrecerle opciones de pago. <br /><br />

            En nuestra empresa, valoramos su relación como cliente y nos esforzamos por brindar un servicio de calidad.

            </font> 
 <br> <br>
            <font style="vertical-align: inherit;"> 
                <font style="vertical-align: inherit;">Para más información por favor contacte al administrador.</font>
            </font>
        </div>
    
    
    </div>
</div>