import { NgModule, isDevMode } from "@angular/core";
import { Routes, RouterModule, PreloadAllModules } from "@angular/router";
import { AuthGuard } from "./services/Security/guard.service";
import { LoginComponent } from "./pages/login/login.component";
import { RecoveryPasswordComponent } from "./pages/recovery-password/recovery-password.component";
import { PageNotFoundComponent } from "./pages/page-not-found/page-not-found.component";
import { PresupuestoRoutes } from "./pages/SaludPlus/Presupuesto/Presupuesto.routing";
import { ImgDiagnosticasRoutes } from "./pages/SaludPlus/ImgDiagnosticas/ImgDiagnosticas.routing";
import { LaboratorioRoutes } from "./pages/SaludPlus/Laboratorio/laboratorio-routing.module";
import { FacturacionRoutes } from "./pages/SaludPlus/Facturacion/Facturacion-routing.module";
import { TriageRoutes } from "./pages/SaludPlus/Triage/Triage.routing";
import { AdministracionRoutes } from "./pages/SaludPlus/Administracion/Administracion.routing";
import { ContabilidadRoutes } from "./pages/SaludPlus/Contabilidad/Contabilidad.routing";
import { HistoriaClinicasRoutes } from "./pages/SaludPlus/HistoriasClinicas/historia-clinica-routing.module";
import { AuditoriasRoutes } from "./pages/SaludPlus/Auditoria/Auditoria.routing";
import { InventarioRoutes } from "./pages/SaludPlus/Inventario/Inventario.routing";
import { CitasRoutes } from "./pages/SaludPlus/Citas/Citas.routing";
import { HomeComponent } from "./pages/home/components/home.component";
import { ImportSettings } from "./components/Core/components/import-records/import-xls-records/import.settings";
import { PagosPendientesComponent } from "./pages/pagos-pendientes/pagos-pendientes.component";
import { ImportRecordsComponent } from "@app/Components/Core/components/import-records/import-xls-records/import-records.component";
import { NoPermisosComponent } from "./pages/no-permisos/no-permisos.component";
import { NominaRoutes } from "./pages/SaludPlus/Nomina/nomina.routing";
import { DeveloperRoutes } from "./pages/developer/develop.routing";
import { SearchInListComponent } from "./core/Search/search-in-list/search-in-list.component";
import { AsistencialRoutes } from "./pages/SaludPlus/Asistencial/asistencial.routing";
import { ImportSpreadsheetRecordsComponent } from "./Components/Core/components/import-records/import-spreadsheet-records/import-spreadsheet.component";
import { DynamicRoutes } from "./pages/dynamic-form/dynamic.routing";

const title = "SaludPlus";


const routes: Routes = [
  {
    path: "inicio",
    loadChildren: () =>
      import(
        "src/app/pages/home/home.module"
      ).then((m) => m.HomeModule),
    canActivate: [AuthGuard],
    data: { title: `${title} - Home` },
  },
  {
    path: "login",
    component: LoginComponent,
    data: { title: `${title} - Login` },
  },
  {
    path: "pendientes",
    component: PagosPendientesComponent,
    data: { title: `${title} - Obligaciones Pendientes` },
  },
  {
    path: "no-permisos",
    component: NoPermisosComponent,
    data: { title: `${title} - No tiene permisos` },
  },
  {
    path: "configuracion",
    component: HomeComponent,
    data: { title: `${title} - Configuración` },
  },
  {
    path: "seleccionar-institucion",
    loadChildren: () =>
      import(
        "src/app/pages/SaludPlus/General/seleccionar-institucion/seleccionar-institucion.module"
      ).then((m) => m.SeleccionarInstitucionModule),
    canActivate: [AuthGuard],
    data: { title: `${title} - Seleccionar Institución` },
  },
  {
    path: "recovery-password/:token",
    component: RecoveryPasswordComponent,
    data: { title: `${title} - Recuperar Contraseña` },
  },
  {
    path: "importar-archivos",
    component: ImportRecordsComponent,
    data: { title: `${title} - Importar Archivo` },
    canActivate: [AuthGuard],
  },
  {
    path: "lista-importaciones",
    component: SearchInListComponent,
    data: { title: `${title} - Importaciones`, setting: ImportSettings },
    canActivate: [AuthGuard],
  },
  {
    path: "importaciones/resultado/:id",
    component: ImportSpreadsheetRecordsComponent,
    data: { title: `${title} - Importaciones Resultado` },
    canActivate: [AuthGuard],
  },


  { path: "", redirectTo: "inicio", pathMatch: "full" },
  {
    path: ":controlador/:accion",
    loadChildren: () =>
      import(
        "src/app/pages/redirect-component/redirect-component.module"
      ).then((m) => m.RedirectComponentModule),
    canActivate: [AuthGuard],
    data: { title: `${title}` },
  },


  { path: "**", component: PageNotFoundComponent },


];



@NgModule({
  imports: [
    DynamicRoutes,
    AdministracionRoutes,
    FacturacionRoutes,
    CitasRoutes,
    HistoriaClinicasRoutes,
    ImgDiagnosticasRoutes,
    LaboratorioRoutes,
    PresupuestoRoutes,
    InventarioRoutes,
    TriageRoutes,
    ContabilidadRoutes,
    AuditoriasRoutes,
    NominaRoutes,
    AsistencialRoutes,
    DeveloperRoutes,
    RouterModule.forRoot(routes,
      {
        enableTracing: false,
        preloadingStrategy: PreloadAllModules,
      }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
