import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';  
import { DynamicFormRoutingModule } from './components/dynamic-form.routing';
import { BancosRoutes } from './views/bancos/bancos.routing';
import { BodegasRoutes } from './views/bodegas/bodegas.routing';
import { EquivalenciasRoutes } from './views/equivalencias-inventario/equivalencias.routing';

 
@NgModule({
  declarations: [],
  imports: [ 
   /* BodegasRoutes,*/  BancosRoutes, EquivalenciasRoutes
  ]

})
export class DynamicRoutes { } 