import { Component, Inject } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { FiltersRecently } from "@src/app/models/base/FiltersRecently";
import { FormSearch } from "@src/app/models/base/FormSearch";
import { SearchTypeFilter } from "@src/app/models/base/Search/SearchType";
import { ControllerApiList } from "@src/app/models/SaludPlus/Controller/ControllerList";
import {
  MultipleFilter,
} from "@src/app/models/Tegett/SimpleFilterData";
import {
  TegettFormControl,
  TypeField,
} from "@src/app/models/Tegett/tegettFormControl";
import { BaseSearchSetting } from "@src/app/models/base/BaseSearchSetting";

@Component({
  selector: "app-bancosSettings",
  template: "",
})
export class BancoSettings implements BaseSearchSetting {
  //  private matcher = new ErroresEstado();

  tieneSede = false;
  listadoEstados: MultipleFilter;
  listadoPerfiles: MultipleFilter;

  constructor(
    @Inject(Router) private router: Router,    
    @Inject(ActivatedRoute) private route: ActivatedRoute
  ) {}

  async Load(){
    return true;   
  }
  /**
   * Funcion para armar la busqueda
   */
   loadFormSearch(){
       
    const UsuarioSearch = new FormSearch();
    UsuarioSearch.formComponents = this.FormComponents();
    UsuarioSearch.configSearch = {
      title: "Bancos",
      titleIcon: "fa fa-university",
      apiSearch: ControllerApiList.Contabilidad.Bancos,
      apiSearchResult: "ListadoBanco",      
      filtersRecently: this.LoadSimpleFilter(),
      buttonNewRecordText: "Nuevo Banco",
      buttonNewRecordClick: () => this.newRecord(),

      settings: [        
        { objectKey: "id", sortV3: "desc", visible: false },        
      ],
      fields: [        
        {
          renderLabel: "ID",
          objectKey: "id",
          autoSize: true,
        },

        {
          objectKey: "nombreBanco",
          renderLabel: "Nombre del banco",          
          pipe: "ColumnCustom", icon: "inherit",
          click: (row) => this.goView(row),
          autoSize: true,
          isLink:true          
        },
        {
          objectKey: "direccionBanco",
          renderLabel: "Direccion",
        },

        {
          objectKey: "telefonoBanco",
          renderLabel: "Telefono",
        }
        
      ],
      filters: [
        { objectKey: "nombreBanco", type: SearchTypeFilter.Text }       
      ],
    };
    return UsuarioSearch;
  }

  /**
   *
   */
  newRecord() {
    this.router.navigate(["../"], { relativeTo: this.route });
  }

  /**
   * ir al registro
   * @param row linea proveniente de la busqueda
   */
  goView(row: any) {
    if (row.id) {
      this.router.navigate(["../", row.id], { relativeTo: this.route });
    }
  }

  /**
   * Componentes del formulario
   */
  FormComponents(): UntypedFormGroup {
    const formRecord = new UntypedFormGroup({
        nombreBanco: new TegettFormControl({
        label: "Nombre del banco",
        typeField: TypeField.Input,        
        required: true,
        nameControl: "nombreBanco",
      }),
      direccionBanco: new TegettFormControl({
        label: "Direccion",
        typeField: TypeField.Input,        
        nameControl: "direccionBanco",
      }),
      
      telefonoBanco: new TegettFormControl({
        label: "Teléfono",
        typeField: TypeField.Input,        
        nameControl: "telefonoBanco",
      })
    });
    return formRecord;
  }
  LoadSimpleFilter(): FiltersRecently[] {
    let filterdataRecently: FiltersRecently[] = [
      {
        field: 0,
        text: "Mis Actividades",
        separator: true,
        value: "1",
      },
      {
        field: 1,
        text: "Actualizado recientemente",
        value: "2",
      },
      {
        field: 2,
        text: "Creado recientemente",
        isDefault: true,
        value: "3",
      },
    ];
    return filterdataRecently;
  } 
}
