import { Routes, RouterModule } from "@angular/router";
import { CentroServiciosRoutingModule } from "./centro-servicios/centro-servicios.routing";
import { CentrosCostosRoutingModule } from "./centros-costos/centros-costos.routing";
import { CupsPropiosRoutingModule } from "./cups-propios/cups-propios.routing";
import { PerfilesRoutes } from "./perfiles/Perfiles.routing";
import { SedesRoutingModule } from "./sedes/sedes.routing";
import { UsuariosRoutes } from "./usuarios/usuarios.routing";
import { CamasRoutingModule } from "./camas/camas.routing";
import { HabitacionesRoutingModule } from "./habitaciones/habitaciones.routing";
import { ConceptosFacturacionRoutingModule } from "./conceptos-facturacion/conceptos-facturacion.routing";
import { EntidadesRoutingModule } from "./entidades/entidades.routing";
import { ArticulosRoutingModule } from "./articulos/articulos.routing";
import { CoPagosRoutingModule } from "./copagos/copagos.routing";
import { ResolucionesDianRoutingModule } from "./resoluciones-dian/resoluciones-dian.routing";
import { InstitucionRoutesModule } from "./institucion/institucion.routing";
import { ListadoConceptosFacturacionRoutingModule } from "./reportes/listado-conceptos-facturacion/listado-conceptos-facturacion.routing";
import { ListadoArticulosRoutingModule } from "./reportes/listado-articulos/listado-articulos.routing";
import { ListadoCamasRoutingModule } from "./reportes/listado-camas/listado-camas.routing";
import { ListadoContratosRoutingModule } from "./reportes/listado-contratos/listado-contratos.routing";
import { ListadoEntidadesRoutingModule } from "./reportes/listado-entidades/listado-entidades.routing";
import { ListadoHabitacionesRoutingModule } from "./reportes/listado-habitaciones/listado-habitaciones.routing";
import { ListadoUsuariosRoutingModule } from "./reportes/listado-usuarios/listado-usuarios.routing";
import { ServiciosChatBotRoutingModule } from "./servicios-chat-bot/servicios-chat-bot.routing";

const routes: Routes = [
  //Perfiles
  PerfilesRoutes.RoutingPerfilesRedirect,
  PerfilesRoutes.RoutingPerfiles,

  // Usuarios
  UsuariosRoutes.RoutingUsuariosRedirect,
  UsuariosRoutes.RoutingUsuarios,

  //CupsPropios
  CupsPropiosRoutingModule.RoutingCupsPRedirect,
  CupsPropiosRoutingModule.RoutingCupsPropios,

  //Centro Costos
  CentrosCostosRoutingModule.RoutingCentroCostosRedirect,
  CentrosCostosRoutingModule.RoutingCentrosCostos,

  //Sedes
  SedesRoutingModule.RoutingSedesRedirect,
  SedesRoutingModule.RoutingSedes,

  //Centro Servicios
  CentroServiciosRoutingModule.RoutingCentroServicosRedirect,
  CentroServiciosRoutingModule.RoutingCentroServicios,

  //Camas
  CamasRoutingModule.RoutingCamasRedirect,
  CamasRoutingModule.RoutingCamas,

  //Habitaciones
  HabitacionesRoutingModule.RoutingHabitacionesRedirect,
  HabitacionesRoutingModule.RoutingHabitaciones,

  //Conceptos de facturacion
  ConceptosFacturacionRoutingModule.RoutingConceptosFacturacionRedirect,
  ConceptosFacturacionRoutingModule.RoutingConceptosFacturacion,

  //Entidades
  EntidadesRoutingModule.RoutingEntidadesRedirect,
  EntidadesRoutingModule.RoutingEntidades,

  //Articulos
  ArticulosRoutingModule.RoutingArticulosRedirect,
  ArticulosRoutingModule.RoutingArticulos,

  //CoPagos
  CoPagosRoutingModule.RoutingCoPagosRedirect,
  CoPagosRoutingModule.RoutingCoPagos,

  //Resoluciones Dian
  ResolucionesDianRoutingModule.RoutingResolucionesDian,

  //institucion
  InstitucionRoutesModule.RoutingInstitucionRedirect,
  InstitucionRoutesModule.RoutingInstitucion,

   //ListadoConceptosFacturacion
   ListadoConceptosFacturacionRoutingModule.RoutingListadoConceptosFacturacion,

   //ListadoArticulos
   ListadoArticulosRoutingModule.RoutingListadoArticulos,

    //ListadoCamas
    ListadoCamasRoutingModule.RoutingListadoCamas,

     //ListadoContratos
   ListadoContratosRoutingModule.RoutingListadoContratos,

    //ListadoEntidades
    ListadoEntidadesRoutingModule.RoutingListadoEntidades,

     //ListadoHabitaciones
   ListadoHabitacionesRoutingModule.RoutingListadoHabitaciones,
    //ListadoUsuarios
    ListadoUsuariosRoutingModule.RoutingListadoUsuarios,
    //ListadoUsuarios
    ListadoArticulosRoutingModule.RoutingListadoArticulos,

    // Servicios Chat Bots
    ServiciosChatBotRoutingModule.RoutingServiciosChatBot

];

export const AdministracionRoutes = RouterModule.forChild(routes);
