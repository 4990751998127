import { Routes, RouterModule } from "@angular/router";
import { EmpleadosRoutingModule } from "./empleados/empleados.routing";
import { ContratosRoutingModule } from "./contratos/contratos.routing";
import { CargosRoutingModule } from "./cargos/cargos.routing";
import { ConfiguracionConceptosRoutingModule } from "./configuracionConceptos/configuracionConceptos.routing";
import { DepartamentosRoutingModule } from "./departamentos/departamentos.routing";
import { SubDepartamentosRoutingModule } from "./sub-departamentos/sub-departamentos.routing";
import { LiquidacionRoutingModule } from "./liquidacion/liquidacion.routing";

const routes: Routes = [
  EmpleadosRoutingModule.RoutingEmpleados,
  ContratosRoutingModule.RoutingContratos,
  CargosRoutingModule.RoutingCargos,
  ConfiguracionConceptosRoutingModule.RoutingConfiguracionConceptos,
  DepartamentosRoutingModule.RoutingDepartamentos,
  SubDepartamentosRoutingModule.RoutingDepartamentos,
  LiquidacionRoutingModule.RoutingLiquidacion
  
];
export const NominaRoutes = RouterModule.forChild(routes);
