import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { AuthenticationService } from 'src/app/services/Security/authentication.service';

@Component({
  selector: 'app-recovery-password',
  templateUrl: './recovery-password.component.html',
  styleUrls: ['./recovery-password.component.scss']
})
export class RecoveryPasswordComponent implements OnInit {
  loginForm: UntypedFormGroup;
  loading = false;
  token = '';
  messageLoading = '';
  submitted = false;
  isError = false;
  changeOk = false;
  mask = true;
  constructor(
    private titleService: Title,
    private authenticationService: AuthenticationService,
    private formBuilder: UntypedFormBuilder,    
    private route: ActivatedRoute 

  ) {

  }

  ngOnInit() {
    this.titleService.setTitle(this.route.snapshot.data['title']);
    this.loginForm = this.formBuilder.group({
      newPassword: ['', [Validators.required, Validators.minLength(6)]],
      newPasswordVerified: ['', Validators.required],
    });

    if(this.authenticationService.IsLogin()){
      this.authenticationService.logout(false);
    }

    this.route.params.subscribe(params => {
      this.token = params['token'];
    });

  }

  // convenience getter for easy access to form fields
  public get f(): any { return this.loginForm.controls; }

  async onSubmit() {
    this.submitted = true;
    this.isError = false;
    this.changeOk = false;

    this.messageLoading = 'Cambiando contraseña...';
    if (this.loginForm.invalid ) {
      if (this.f.newPassword.value.length < 6 ) {
        this.messageLoading =  'La contraseña de ser mínimo de 6 caracteres';
        this.isError = true;
        return;
      }
      return;
    }
    if (this.f.newPassword.value !== this.f.newPasswordVerified.value) {
      this.messageLoading = 'Las contraseñas no son iguales';
      this.isError = true;
      return;
    }
    if (this.f.newPassword.value.includes(' ')) {
      this.messageLoading = 'Contraseña no debe contener espacios';
      this.isError = true;
      return;
    }
    try {
      this.loading = true;
      this.messageLoading = 'Cambiando contraseña...';
      const dataLogin = await this.authenticationService.changePassword(this.f.newPassword.value, this.token);
      if (dataLogin) {
        this.messageLoading = 'Contraseña cambiada correctamente\nRedirigiendo al login...';
        this.isError = false;
        this.changeOk = true;

        setTimeout( () => {
          document.location.href = '/login';
        }, 5000);


      } else {
        this.messageLoading = 'Se presentó un problema en el sistema y no se logró actualizar su contraseña, por favor inténtelo más tarde';
        this.isError = true;
      }




    } catch (error) {
      console.log('error', error)
      this.messageLoading = "error, no se pudo cambiar la contraseña"
      this.messageLoading = error ? error.error.message : "Enlace vencido";
      this.isError = true;
      this.loading = false;
      this.loginForm.reset();
    }

  }



}
