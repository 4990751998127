export class LCaracteristicas {
    idCaracteristica: number;
    nombre: string;
    descripcion: string;
    estado: number;
    fechaInicial: any;
    fechaFinal: any;
    fkRecurso: number;
    ubicacionCaracteristica: string;
    claseGraficaCssCaracteristica: string;
    controladorCaracteristica: string;
    funcionCaracteristica: string;
    ordenAparicionCaracteristica: number;
    estiloCuadro: string;
    enContruccion: boolean;
    caracteristicas = [];   
    favorito: boolean;
    ruta: string;
}
