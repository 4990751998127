import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'normalizeMenu'
})
export class NormalizeMenuPipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {
    switch (value) {
      case '/facturacion/Editarpacientes':
        value = "facturacion/pacientes"
        break;
    
      default:
        break;
    }

    return value
   
  }

}
