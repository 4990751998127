import { Routes, RouterModule } from "@angular/router";
import { ConsultoriosRoutingModule } from './consultorios/consultorios.routing';
import { CitasCanceladasInasistenteRoutingModule } from "./reportes/citas-canceladas-inasistentes/citas-canceladas-inasistente.routing";

const routes: Routes = [
  //Consultorios
  ConsultoriosRoutingModule.RoutingConsultoriosRedirect,
  ConsultoriosRoutingModule.RoutingConsultorios,

  CitasCanceladasInasistenteRoutingModule.RoutingCitasCanceladasInasistente

];

export const CitasRoutes = RouterModule.forChild(routes);
