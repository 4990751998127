export class LRecursos {
    idRecurso: number;
    nombre: string;
    descripcion: string;
    activo: string;
    claseGraficaCssRecursoMenu: string;
    claseGraficaCssRecursoBotones: string;
    ordenAparicionRecurso: number;
    enContruccion: boolean;
    panelOpenState = false;
}
