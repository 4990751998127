import { Routes, RouterModule } from "@angular/router";
import { AuthGuard } from "@app/services/Security/guard.service";
import { AnexoTecnicoAutorizacionesRoutingModule } from "./AnexoTecnicoAutorizaciones/anexo-tecnico-autorizaciones.routing";
import { AnexoTecnicoInformeAtencionUrgenciaRoutingModule } from "./AnexoTecnicoInformeAtencionUrgencia/anexo-tecnico-atencion-urgencia.routing";
import { FurtranRoutingModule } from "./Furtran/furtran.routing";
import { PacientesRoutingModule } from "./Pacientes/pacientes.routing";
import { ListadoAdmisionesRoutingModule } from "./reportes/listado-admisiones/listado-admisiones.routing";
import { ListadoConsultasFacturadosRoutingModule } from "./reportes/listado-consultas-facturados/listado-consultas-facturados.routing";
import { ListadoEstanciaFacturadosRoutingModule } from "./reportes/listado-estancia-factura/listado-estancia-facturados.routing";
import { ListadoMaterialesInsumosFacturadosRoutingModule } from "./reportes/listado-materiales-insumos-facturados/listado-materiales-insumos-facturados.routing";
import { ListadoMedicamentosFacturadosRoutingModule } from "./reportes/listado-medicamentos-facturados/listado-medicamentos-facturados.routing";
import { ListadoOtrosConceptosFacturadosRoutingModule } from "./reportes/listado-otros-conceptos-facturados/listado-otros-conceptos-facturados.routing";
import { ListadoProcedimientosFacturadosRoutingModule } from "./reportes/listado-procedimientos-facturados/listado-procedimientos-facturados.routing";
import { ListadoObservacionesFacturadosRoutingModule } from "./reportes/listados-observaciones-facturados/listado-observaciones-facturados.routing";
import { TrasladoRoutingModule } from "./Traslados/traslados.routing";
import { FuripsRoutingModule } from "./Furips/furips.routing";
import { ListadoPacientesRoutingModule } from "./reportes/listado-pacientes/listado-pacientes.routing";
import { ListadoNotasCreditoRoutingModule } from "./reportes/listado-notas-credito/listado-notas-credito.routing";
import { ListadoNotasDebitoRoutingModule } from "./reportes/listado-notas-debito/listado-notas-debito.routing";
import { AnexoTecnicoUnoRoutingModule } from "./AnexoTecnicoUno/anexo-tecnico-uno.routing";




const routes: Routes = [
  // Paciente
  PacientesRoutingModule.RoutingPacienteRedirect,
  PacientesRoutingModule.RoutingPaciente,

  // Anexo tecnico autorizaciones
  AnexoTecnicoAutorizacionesRoutingModule.RoutingAnexoAutorizacionesRedirect,
  AnexoTecnicoAutorizacionesRoutingModule.RoutingAnexoAutorizaciones,

  //listado de facturas
  // ListadoFacturasRoutesModule.RoutingListadoFacturasRedirect,
  //ListadoFacturasRoutesModule.RoutingListadoFacturas

  // Listado MedicamentosFacturados
  ListadoMedicamentosFacturadosRoutingModule.RoutingListadoMedicamentos,

  // Listado MaterialesInsumosFacturados
  ListadoMaterialesInsumosFacturadosRoutingModule.RoutingListadoMaterialesInsumos,

  //Listado OtrosConceptosFacturados
  ListadoOtrosConceptosFacturadosRoutingModule.RoutingListadoOtrosConceptos,

  //Listado ConsultasFacturados
  ListadoConsultasFacturadosRoutingModule.RoutingListadoConsultasRedirect,
  ListadoConsultasFacturadosRoutingModule.RoutingListadoConsultas,

  //Listado ProcedimientosFacturados
  ListadoProcedimientosFacturadosRoutingModule.RoutingListadoProcedimientosRedirect,
  ListadoProcedimientosFacturadosRoutingModule.RoutingListadoProcedimeintos,

  //Listado ObservacionesFacturados
  ListadoObservacionesFacturadosRoutingModule.RoutingListadoObservaciones,

  //Listado EstanciasFacturadas
  ListadoEstanciaFacturadosRoutingModule.RoutingListadoEstanciaRedirect,
  ListadoEstanciaFacturadosRoutingModule.RoutingListadoEstancia,

  //listado Admisiones
  ListadoAdmisionesRoutingModule.RoutingAdmisionesRedirect,
  ListadoAdmisionesRoutingModule.RoutingAdmisiones,

  //listado prefacturas
  // ListadoPrefacturasRoutingModule.RoutingPrefacturasRedirect,
  // ListadoPrefacturasRoutingModule.RoutingPrefacturas,

  // Furips
  FuripsRoutingModule.RoutingFuripsRedirect,
  FuripsRoutingModule.RoutingFurips,

  //Furtran
  FurtranRoutingModule.RoutingFurtranRedirect,
  FurtranRoutingModule.RoutingFurtran,

  //Anexo Técnico Informe Atención Urgencia
  AnexoTecnicoInformeAtencionUrgenciaRoutingModule.RoutingAnexoTecnicoInformeAtencionUrgenciaRedirect,
  AnexoTecnicoInformeAtencionUrgenciaRoutingModule.RoutingAnexoTecnicoInformeAtencionUrgencia,

  //Traslados
  TrasladoRoutingModule.RoutingTrasladoRedirect,
  TrasladoRoutingModule.RoutingTraslado,

  //listado pacientes
  ListadoPacientesRoutingModule.RoutingPacientes,

  // LIstado Notas Credito
  ListadoNotasCreditoRoutingModule.RoutingListadoNotasCredito,

    // LIstado Notas Debito
    ListadoNotasDebitoRoutingModule.RoutingListadoNotasDebito,

    // Anexo
    //AnexoTecnicoUnoRoutingModule.RoutingAnexoTecnicoUno



];
export const FacturacionRoutes = RouterModule.forChild(routes);
