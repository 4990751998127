import { Routes, RouterModule } from "@angular/router";
import { SolicitudMedicamentosRoutingModule } from "./solicitud-medicamentos/solicitud-medicamentos.routing";


const routes: Routes = [


  SolicitudMedicamentosRoutingModule.RoutingSolicitudMedicamentos,


];
export const AsistencialRoutes = RouterModule.forChild(routes);
