import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { AuthenticationService } from "@src/app/services/Security/authentication.service";

@Component({
  selector: "app-no-permisos",
  templateUrl: "./no-permisos.component.html",
  styleUrls: ["./no-permisos.component.scss"],
})
export class NoPermisosComponent implements OnInit {
  url = "";
  usuario = "";
  name = "";
  perfiles: any;
  constructor(
    private authenticationService: AuthenticationService,
    private activateRoute: ActivatedRoute) {}
  ngOnInit(): void {
    this.activateRoute.queryParams.subscribe((params) => {
      this.url = params["url"];
    });

    this.name = this.authenticationService.getName(); 
    this.usuario = this.authenticationService.getUsuario();
    this.perfiles = this.authenticationService.getPerfiles();
  }

    /**
   * Cerrar sesion
   */
    logout() {
      this.authenticationService.logout(); 
    }
}
