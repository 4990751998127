import { Routes, RouterModule } from "@angular/router";
import { CuentasBancariasRoutingModule } from "./cuentas-bancarias/cuentas-bancarias.routing";
import { PlanCuentasRoutingModule } from "./plan-cuentas/plan-cuentas.routing";
import { ListadoDocumentosDescuadradosRoutingModule } from "./reportes/listado-documentos-descuadrados/listado-documentos-descuadrados.routing";
import { ListadoRetencionesRoutingModule } from "./reportes/listado-retenciones/listado-retenciones.routing";
import { VigenciasContabilidadRoutingModule } from "./vigencias/vigencias-routing.module";
import { TercerosRoutingModule } from './terceros/terceros.routing';
import { ComprobantesContabilidadRoutingModule } from "./comprobantes-contabilidad/comprobantes-contabilidad.routing";
import { ListadoTercerosRoutingModule } from "./reportes/listado-terceros/listado-terceros.routing";
import { DocumentoSoporteRoutingModule } from "./documento-soporte/documento-soporte.routing";


const routes: Routes = [
  // Listado retenciones
  ListadoRetencionesRoutingModule.RoutingListadoRetenciones,

  // Listado Documentos decuadrados
  ListadoDocumentosDescuadradosRoutingModule.RoutingListadoDocumentosDescuadrados,

  // Vigencias
  VigenciasContabilidadRoutingModule.RoutingVigencias,

  //Cuentas Bancarias
  CuentasBancariasRoutingModule.RoutingCuentasBancariasRedirect,
  CuentasBancariasRoutingModule.RoutingCuentasBancarias,

  //Plan de cuentas
  PlanCuentasRoutingModule.RoutingPlanCuentasRedirect,
  PlanCuentasRoutingModule.RoutingPlanCuentas,

  //Terceros
  TercerosRoutingModule.RoutingTercerosRedirect,
  TercerosRoutingModule.RoutingTerceros,

  //Comprobantes de contabilidad
  //ComprobantesContabilidadRoutingModule.RoutingComprobantesContabilidadRedirect,
  //ComprobantesContabilidadRoutingModule.RoutingComprobantesContabilidad,

  //Listado Terceros
  ListadoTercerosRoutingModule.RoutingListadoTercerosRedirect,
  ListadoTercerosRoutingModule.RoutingListadoTerceros,

  // Documento Soporte
  DocumentoSoporteRoutingModule.RoutingDocumentoSoporte,
];
export const ContabilidadRoutes = RouterModule.forChild(routes);
