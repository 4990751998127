import { UntypedFormGroup } from "@angular/forms"; 
import { SPlusMoreOption, SPlusOption } from "@saludplus/forms";



export declare interface BaseDynamicFormComponent {     
    form: DynamicForm; 

    NewRecord(): void;
    EditRecord(id: number): void;
    DeleteRecord(id: number): void;
    SaveRecord(form: UntypedFormGroup): void;
    CancelRecord(): void;
    GetRecord(id: number): void;
    GetRecords(): void;   
}


 
export class DynamicForm {
     
    title: string; 
    recordName: string; 
    fieldViewModeName?: string; 
    header?:FormHeader;
    formRecord: UntypedFormGroup;
    endpointbase: string;
    groups: Groups[];
}

export class FormHeader  {
    options? : SPlusOption[];
    moreOptions? : SPlusMoreOption[];  
}


export class Groups {
     
    /** titulo del grupo */
    title: string;  
    /** campos del grupo */
    fields: Fields[];
}


export class Fields {
      
    control: string;  
    controlType?: ControlType = ControlType.textbox;
}


export enum ControlType {
    textbox = 'textbox',
    numerictextbox = 'numerictextbox',
    textarea = 'textarea',    
  } 
  


  export enum IconType {
    search = 'fa-solid fa-magnifying-glass',   
  } 
  