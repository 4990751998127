<splus-forms>
  <div class="flex justify-center items-center" id="mainRecoveryPassword">

    <div class="flex justify-center items-center">

      <div class="main-login">
        <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
          <div appearance="outlined" class="login-card">
            <div class="login-card-header">
              <div class="text-center">
                <img src="assets/images/logosaludplus.png">
              </div>
            </div>
            <div class="login-card-content">

              <div class="flex justify-center items-center">

                <i class="fa-duotone fa-lock fa-lg text-primary splus-padding-right-4"></i>
                <input class="login-input width-75 login-input-xs" [type]="mask ? 'password' : 'input'"
                  placeholder="Nueva Contraseña" formControlName="newPassword" id="newPassword"
                  autocomplete="new-password" [ngClass]="{'submit-error' : submitted && f.newPassword.errors}" />
                <i [ngClass]="{'fa-eye-slash': mask, 'fa-eye': !mask}"
                  class="fa-solid  fa-lg text-primary splus-padding-left-4 link" (click)="mask = !mask"></i>

              </div>
              <div class="flex justify-center items-center splus-margin-top-20 splus-padding-right-29">

                <i class="fa-duotone fa-lock fa-lg text-primary splus-padding-right-4"></i>
                <input class="login-input width-75 login-input-xs" id="verifyPassword"
                  [type]="mask ? 'password' : 'input'" placeholder="Verifiqué nueva contraseña"
                  formControlName="newPasswordVerified" autocomplete="new-password"
                  [ngClass]="{'submit-error' : submitted && f.newPasswordVerified.errors}" />


              </div>

              <div class="loadingInformation splus-margin-top-10" [ngClass]="{'isError': isError}">

                <i *ngIf="messageLoading && changeOk" class="fa-solid fa-check text-green-500" ></i>

                {{messageLoading }}
              </div>

            </div>
            <div class="p-5 pl-8 pt-4 pb-4 flex flex-col md:flex-row md:justify-around items-center">
              <button class="login-button  mx-auto md:mx-0 w-full md:w-auto" type="submit" color="primary"
                [disabled]="loading" id="btnCambiar">Aceptar</button>

               
                <a class="password-recovery text-primary cursor-pointer underline" id="recoveryPassword" [routerLink]="['/login']">Cancelar</a>


            </div>
          </div>

        </form>
      </div>
    </div>
  </div>