<splus-forms>
  <div class="flex justify-center items-center" id="loginMain">

    <div class="main-login ">

      <form [formGroup]="loginIn ? loginForm : emailFormControl" (ngSubmit)="loginIn ? onSubmit() :  EmailRecovery()">

        <div class=" text-right mr-1 mb-1">
          <a (click)="onChangeSwitch()" class="theme-swich cursor-pointer text-sm" href="javascript:;">
            <i *ngIf="themeSwitcher" class="fa-thin fa-sun-bright  fa-lg"></i>
            <i *ngIf="!themeSwitcher" class="fa-thin fa-moon-stars fa-lg"></i>
            Tema
          </a>
        </div>
        <div appearance="outlined" class="login-card bg-white bg-opacity-25 dark:bg-neutral-700 rounded-lg">
          <div class="login-card-header">
            <div style="text-align: center">
              <img src="assets/images/logosaludplus.png">
            </div>
          </div>
          <div class="login-card-content">

            <div *ngIf="loginIn" class="flex justify-center items-center">
              <i class="fa-regular fa-user fa-lg text-primary dark:text-blue-500 splus-padding-right-4"></i>

              <input class="login-input dark:text-white bg-white bg-opacity-40 border border-gray-400  " id="login"
                placeholder="Usuario" formControlName="login" autocomplete="login"
                [ngClass]="{'submit-error' : submitted && f.login.errors && f.login.errors.required}" />


            </div>
            <div *ngIf="loginIn" class="flex justify-center items-center mt-6">

              <i class="fa-solid fa-lock fa-lg text-primary dark:text-blue-500  splus-padding-right-4"></i>
              <input class="login-input dark:text-white  bg-white bg-opacity-40 border border-gray-400" id="password"
                type="password" placeholder="Contraseña" formControlName="password" autocomplete="new-password"
                [ngClass]="{'submit-error' : submitted && f.password.errors && f.password.errors.required}" />

            </div>

            <div *ngIf="!loginIn" class="flex justify-center items-center">
              <i class="fa-regular fa-envelope fa-lg text-primary  dark:text-blue-500 splus-padding-right-4"></i>

              <input [(ngModel)]="emailRecovery" id="recoveryEmail"
                class="login-input width-75 dark:text-white bg-white bg-opacity-40 border border-gray-400"
                [ngClass]="{'submit-error-diag' : emailError && emailFormControl.invalid}"
                placeholder="Correo Electrónico" [formControl]="emailFormControl">


            </div>

          </div>

          <div class="loadingInformation text-primary dark:text-white" [ngClass]="{'isError': isError}">




            <i *ngIf="messageLoading && emailOk" class="fa-solid fa-check text-green-500"></i>

            {{messageLoading }}
          </div>


          <div class="p-5 pl-8 pt-4 pb-4 flex flex-col md:flex-row md:justify-around items-center">


            <button *ngIf="loginIn"
              class="login-button bg-primary mx-auto md:mx-0 w-full md:w-auto flex items-center justify-center"
              type="submit" id="enter" [disabled]="loading">

              @if(loading){
              <svg class="mr-3 h-5 w-5 animate-spin text-white" xmlns="http://www.w3.org/2000/svg" fill="none"
                viewBox="0 0 24 24">
                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                <path class="opacity-75" fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
                </path>
              </svg>
              }

              @else{

              <span>ENTRAR</span>
              }

            </button>
            <button *ngIf="!loginIn" class="login-button bg-primary mx-auto md:mx-0 w-full md:w-auto" type="submit"
              [disabled]="emailRecovery==''" id="btnRecuperar">Recuperar contraseña</button>
            <a *ngIf="loginIn" class="password-recovery text-primary dark:text-white cursor-pointer underline"
              id="recoveryPassword" (click)="loginIn = false">Recuperar contraseña</a>
            <a *ngIf="!loginIn" class="password-recovery text-primary dark:text-white cursor-pointer underline"
              id="recoveryPassword" (click)="loginIn = true">Volver</a>
          </div>


        </div>
        <div class="copyright text-primary  ">Copyright © {{currentYear}}</div>
        <div class="copyright text-primary text-xs ">v {{version}}</div>

      </form>
    </div>
  </div>