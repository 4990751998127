import { ActivatedRoute } from "@angular/router";
import { AfterViewInit, Component, OnInit} from "@angular/core";
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
  UntypedFormControl,
} from "@angular/forms";
import { Title } from "@angular/platform-browser";
import { AuthenticationService } from "src/app/services/Security/authentication.service";
import { SaludPlusService } from "src/app/services/SaludPlus/saludPlus.service";
import { PersistenceService } from "@app/services/Utilities/data.persistence.service";
import { LocalStorageList } from "@app/helpers/enums/enums.keys"; 
import { ThemeService } from "@src/app/services/Utilities/theme.service";
 
@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit, AfterViewInit {
  

  loginIn = true;
  loginForm: UntypedFormGroup;
  loading = false;
  submitted = false;
  emailError = false;
  emailOk = false;
  isError = false;
  returnUrl: string;
  messageLoading = "";
  emailRecovery = ""; 
  emailFormControl = new UntypedFormControl("", [
    Validators.required,
    Validators.email,
  ]);
  serverConexion = "";
  themeSwitcher = false;

  currentYear: number = new Date().getFullYear();
  version: string = '';
  
  constructor(
    private titleService: Title,
    private route: ActivatedRoute,
    private authenticationService: AuthenticationService, 
    private formBuilder: UntypedFormBuilder,
    private saludPlusService: SaludPlusService,     
    private persistenceService: PersistenceService,
    private themeService: ThemeService
  ) {
    if (authenticationService.IsLogin()) { 
      document.location.href = "/"; 
    } 

    this.themeSwitcher = themeService.isDarkModeMode();
  }

  ngOnInit() {
    this.titleService.setTitle(this.route.snapshot.data["title"]);
    this.loginForm = this.formBuilder.group({
      login: ["", Validators.required],
      password: ["", Validators.required],
    });
  }

  // convenience getter for easy access to form fields
  public get f(): any {
    return this.loginForm.controls;
  }

  
  ngAfterViewInit(): void {
    this.themeService.apply();

    this.version = this.generateVersion();
  }
  public onChangeSwitch(): void {
    if (this.themeSwitcher) {
      this.themeService.setLightMode();
    } else {
      this.themeService.setDarkMode();
    }
    this.themeSwitcher = !this.themeSwitcher;
  }


  async onSubmit() {

    this.isError = false;
    this.submitted = true;
    this.emailOk = false;

    if (this.loginForm.invalid) {
      return;
    }

    this.loading = true;

    try {
      this.messageLoading = "Validando usuario";
      const dataLogin = await this.authenticationService.login(
        this.f.login.value,
        this.f.password.value
      );
      if (dataLogin) {

        // configuracion del usuario
        await this.authenticationService.ConfiguracionPorUsuario(
          false
        );

        this.messageLoading = "Cargando institución";
        const data = await this.authenticationService.ListadoInstitucionesPorUsuario();
        await this.saludPlusService.ValidaSedes();
        await this.saludPlusService.ConfOrdenesPago();
        if (data.length === 0) {
          this.messageLoading = "Este usuario no tiene institución asignada";

        } else if (data.length === 1) {

          // Menu Principal
          this.messageLoading = "Cargando módulos";
          await this.authenticationService.ListadoRecursosPorInstitucion(false);

          // Caracteristicas del menu principal
          this.messageLoading = "Cargando característica";
          await this.authenticationService.ListadoCaracteristicasPorUsuario(
            false
          );
 

          this.persistenceService.setData(
            LocalStorageList.nombre_institucion,
            data[0].nombreInstitucion
          );
          this.persistenceService.setData(
            LocalStorageList.id_institucion,
            data[0].idInstitucion
          );

          document.location.href =
            this.returnUrl === undefined ? "/" : this.returnUrl;
        } else {
          document.location.href = "/seleccionar-institucion";
        }
        this.loading = false;
      }
    } catch (HttpErrorResponse) {
      let err = "";
      if (HttpErrorResponse.status === 0) {
        err = "El servidor no está disponible";
        this.isError = true;

        const origin = new URL(HttpErrorResponse.url).origin;

        const matches = HttpErrorResponse.url.match(
          /^https?\:\/\/([^\/:?#]+)(?:[\/:?#]|$)/i
        );
        
        this.serverConexion = "El servidor<br><b>'" + origin + "/Api</b>'<br>no está disponible ";
      } else {
        err =
          HttpErrorResponse.error.message === undefined
            ? HttpErrorResponse.statusText
              ? HttpErrorResponse.statusText
              : "Error desconocido"
            : HttpErrorResponse.error.message;
      }
      
      this.messageLoading = err;
      this.isError = true;
      this.loading = false;
    }
  }
  /**
   * Enviar email para cambio de contraseña
   */
  EmailRecovery() {
    this.isError = false;
    this.loading = true;
    if (this.emailFormControl.invalid) {
      this.emailError = true;
      return;
    } else {
      this.messageLoading = "Validando Correo";
      this.emailError = false;
      this.isError = false;
      this.emailOk = false

      this.authenticationService
        .RecoveryPassword(this.emailRecovery)
        .subscribe({
          next: (res) => {
            if (res.isSuccessful) {
              this.messageLoading = "Correo enviado correctamente";
              this.emailOk = true;
              this.isError = false;

          
             let me = this;
            setTimeout( () => { 
              me.loginIn = true
            }, 500);  


            } else if (!res.isSuccessful && !res.isError) {
              this.messageLoading = res.messages.join(",");
              this.emailOk = false;
              this.isError = true;
            }
            this.loading = false;
          },
          error: (err) => {
            console.warn("Error critico", err);

            this.messageLoading = "No se pudo enviar el correo electrónico intento más tarde";
            this.isError = true;
            this.emailOk = false;
            this.loading = false;
          },
        });
    }
  }

  private generateVersion(): string {
    const now = new Date();
    const year = now.getFullYear().toString().slice(-2);
    const month = (now.getMonth() + 1).toString().padStart(2, '0');
    const day = now.getDate().toString().padStart(2, '0');
    const hour = now.getHours().toString().padStart(2, '0'); 

    return `2.${year}.${month}.${day}.${hour}`;
  }
}
