import { RouterModule, Routes } from "@angular/router";
import { ArticulosRoutingModule } from "../Administracion/articulos/articulos.routing";
import { BodegasRoutes } from "./bodegas/bodegas.routing";
import { GruposInventarioRoutingModule } from "./grupos-inventario/grupos-inventario.routing";
import { RetencionesRoutingModule } from "./retenciones/retenciones.routing";
import { TiposNotasRoutingModule } from "./tipos-notas/tipos-notas.routing";
import { EntregaHospitalizacionRoutingModule } from "./entrega-hospitalizacion/entrega-hospitalizacion.routing";

const routes: Routes = [


    //Bodegas
    BodegasRoutes.RoutingBodegasRedirect,
    BodegasRoutes.RoutingBodegas,

    //Grupo de inventario
    GruposInventarioRoutingModule.RoutingGruposInventarioRedirect,
    GruposInventarioRoutingModule.RoutingGruposInventario,

    //Retenciones
    RetencionesRoutingModule.RoutingRetencionesRedirect,
    RetencionesRoutingModule.RoutingRetenciones,

    //Tipos de notas
    TiposNotasRoutingModule.RoutingTiposNotasRedirect,
    TiposNotasRoutingModule.RoutingTiposNotas,

    //Articulos
    ArticulosRoutingModule.RoutingArticulosInventarioRedirect,
    ArticulosRoutingModule.RoutingArticulosInventario,

    // Entrega Hospitalizacion
    EntregaHospitalizacionRoutingModule.RoutingEntregaHospitalizacion

  ];
  export const InventarioRoutes = RouterModule.forChild(routes);
