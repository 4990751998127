import { Routes, RouterModule } from '@angular/router';
import { AppSettings } from '@src/app/helpers/AppSettings';
import { AuthGuard } from '@src/app/services/Security/guard.service';
import { EquivalenciasPage } from './equivalencias.page';
import { EquivalenciasSettings } from './equivalenciasSettings';

const routes: Routes = [
  //redirect
  {
    path: "inventario/editarEquivalencias", redirectTo: "inventario/equivalencias"
  },

  //Routing principal
  {
    path: "inventario/equivalencias",
    loadChildren: () =>
      import("@app/pages/dynamic-form/components/dynamic-form.module").then((m) => m.DynamicFormModule),
    canActivate: [AuthGuard],
    data: {
      title: `Equivalencias`,
      icon: "fa fa-random",
      settingPage: EquivalenciasPage ,    
      setting: EquivalenciasSettings 
    },
  }
];

export const EquivalenciasRoutes = RouterModule.forChild(routes);
