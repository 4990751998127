import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '@src/app/services/Security/guard.service';
import { BancosPage } from './bancos.page';
import { BancoSettings } from './bancos.settings';

const routes: Routes = [
  //redirect
  {
    path: "contabilidad/EditarBancos", redirectTo: "contabilidad/bancos"
  },

  //Routing principal
  {
    path: "contabilidad/bancos",
    loadChildren: () =>
      import("@app/pages/dynamic-form/components/dynamic-form.module").then((m) => m.DynamicFormModule),
    canActivate: [AuthGuard],
    data: {
      title: `Bancos`,
      icon: "fa fa-university",
      settingPage: BancosPage,
      setting: BancoSettings 
      
    },
  }
];

export const BancosRoutes = RouterModule.forChild(routes);




