import { Routes, RouterModule } from '@angular/router';
import { IngresoPacienteRoutingModule } from './ingreso-paciente/ingreso-paciente.routing';
import { NivelesTriageRoutingModule } from './niveles-triage/niveles-triage.routing';
import { ListadoIngresoPacientesRoutes } from './reportes/listado-Ingreso-pacientes/listado-Ingreso-pacientes.routing';
import { ListadoValoracionTriageRoutes } from './reportes/listado-valoracion-triage/listado-valoracion-triage.routing';
import { ValoracionTriageRoutingModule } from './valoracion-triage/valoracion-triage.routing';

const routes: Routes = [

  //Listado Ingreso Pacientes
  ListadoIngresoPacientesRoutes.RoutingListadoIngresoPacientes,

  //Listado Valoracion Triage
  ListadoValoracionTriageRoutes.RoutingListadoValoracionTriage,

  //Valoración Triage
  ValoracionTriageRoutingModule.RoutingValoracionTriageRedirect,
  ValoracionTriageRoutingModule.RoutingValoracionTriage,

  //Ingreso Paciente
  IngresoPacienteRoutingModule.RoutingIngresoPacienteRedirect,
  IngresoPacienteRoutingModule.RoutingIngresoPaciente,

  //Niveles Triage
  NivelesTriageRoutingModule.RoutingNivelesTriageRedirect,
  NivelesTriageRoutingModule.RoutingNivelesTriage,
  
];

export const TriageRoutes = RouterModule.forChild(routes);
