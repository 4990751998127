import { Component, Inject } from "@angular/core";
import { UntypedFormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { FiltersRecently } from "@src/app/models/base/FiltersRecently";
import { FormSearch } from "@src/app/models/base/FormSearch";
import { SearchTypeFilter } from "@src/app/models/base/Search/SearchType";
import { ControllerApiList } from "@src/app/models/SaludPlus/Controller/ControllerList";
import {
  MultipleFilter,
} from "@src/app/models/Tegett/SimpleFilterData";
import {
  TegettFormControl,
  TypeField,
} from "@src/app/models/Tegett/tegettFormControl";
import { BaseSearchSetting } from "@src/app/models/base/BaseSearchSetting";

@Component({
  selector: "app-equivalencia",
  template: "",
})
export class EquivalenciasSettings implements BaseSearchSetting {
  //  private matcher = new ErroresEstado();

  tieneSede = false;
  listadoEstados: MultipleFilter;
  listadoPerfiles: MultipleFilter;

  constructor(
    @Inject(Router) private router: Router,    
    @Inject(ActivatedRoute) private route: ActivatedRoute
  ) {}

  async Load(){
    return true;   
  }
  /**
   * Funcion para armar la busqueda
   */
   loadFormSearch(){
       
    const UsuarioSearch = new FormSearch();
    UsuarioSearch.formComponents = this.FormComponents();
    UsuarioSearch.configSearch = {
      title: "Equivalencias",
      titleIcon: "fa fa-random",
      apiSearch: ControllerApiList.Inventario.Equivalencias,
      apiSearchResult: "ListadoEquivalencia",      
      filtersRecently: this.LoadSimpleFilter(),
      buttonNewRecordText: "Nueva Equivalencia",
      buttonNewRecordClick: () => this.newRecord(),

      settings: [        
        { objectKey: "id", sortV3: "desc", visible: false },        
      ],
      fields: [
        {
          objectKey: "option",
          renderLabel: "OPCIONES",
          value: () => "",
          render: () =>
            '<span><a class="text-link" >Ver </a></span>',
          click: (row) => this.goView(row),
          noMap: true,
        },
        {
          renderLabel: "ID",
          objectKey: "id",
          autoSize: true,
        },

        {
          objectKey: "nombreEquivalenciaInventario",
          renderLabel: "Nombre de la equivalencia",          
          pipe: "ColumnCustom", icon: "inherit",
          click: (row) => this.goView(row),
          autoSize: true,
          isLink:true          
        },
        {
          objectKey: "unidadesEquivalenciaInventario",
          renderLabel: "Unidades Equivalencia",
        },
        {
          objectKey: "descripcionEquivalenciaInventario",
          renderLabel: "Descripción",
        }
        
      ],
      filters: [
        { objectKey: "nombreEquivalenciaInventario", type: SearchTypeFilter.Text }       
      ],
    };
    return UsuarioSearch;
  }

  /**
   *
   */
  newRecord() {
    this.router.navigate(["../"], { relativeTo: this.route });
  }

  /**
   * ir al registro
   * @param row linea proveniente de la busqueda
   */
  goView(row: any) {
    if (row.id) {
      this.router.navigate(["../", row.id], { relativeTo: this.route });
    }
  }

  /**
   * Componentes del formulario
   */
  FormComponents(): UntypedFormGroup {
    const formRecord =  new UntypedFormGroup({
      // nombreEquivalenciaInventario
      nombreEquivalenciaInventario: new TegettFormControl({
        formState: "",
        validatorOrOpts: [Validators.required],
        asyncValidator: undefined,
        label: "Nombre Equivalencia",
        typeField: TypeField.Input,
        required: true,
        nameControl: "nombreEquivalenciaInventario",
      }),
      // unidadesEquivalenciaInventario
      unidadesEquivalenciaInventario: new TegettFormControl({
        formState: "",
        validatorOrOpts: [Validators.required],
        label: "Unidades Equivalencia",
        typeField: TypeField.Input,
        nameControl: "unidadesEquivalenciaInventario",
      }),
      // descripcionEquivalenciaInventario
      descripcionEquivalenciaInventario: new TegettFormControl({
        formState: "",
        label: "Descripción",
        typeField: TypeField.Input,
        funtionData: undefined,
        required: false,
        dataTextField: undefined,
        dataValueField: undefined,
        importNoRequired: false,
        parentDependency: undefined,
        nameControl: "descripcionEquivalenciaInventario",
      }),
    });
    return formRecord;
  }
  LoadSimpleFilter(): FiltersRecently[] {
    let filterdataRecently: FiltersRecently[] = [
      {
        field: 0,
        text: "Mis Actividades",
        separator: true,
        value: "1",
      },
      {
        field: 1,
        text: "Actualizado recientemente",
        value: "2",
      },
      {
        field: 2,
        text: "Creado recientemente",
        isDefault: true,
        value: "3",
      },
    ];
    return filterdataRecently;
  } 
}
