import { HttpBackend, HttpRequest } from "@angular/common/http";
import { Injectable, NgZone } from "@angular/core";
import { INGXLoggerMetadata, NGXLoggerServerService, NgxLoggerLevel } from "ngx-logger";
import { AuthenticationService } from "../services/Security/authentication.service";
import { SettingsService } from "../services/Utilities/settings.service";
import { LocalStorageList } from "./enums/enums.keys";

@Injectable()
export class AuthTokenServerService extends NGXLoggerServerService {
  baseUrl: string;
  constructor(private settingsService: SettingsService, httpBack: HttpBackend, ngZone: NgZone) {
    super(httpBack,ngZone);
    this.baseUrl = this.settingsService.getBaseUrlApi;
  }


    public override customiseRequestBody(metadata: INGXLoggerMetadata): any {
      let body = { ...metadata };
      //body['levelName'] = NgxLoggerLevel[metadata.level];

      // note, for the example we log the body but in a real case the log is useless
      //console.log('Customised body is', body);

      return body;
    }


  protected override alterHttpRequest(request: HttpRequest<any>): HttpRequest<any> {

    const newUrl = { url: `${this.baseUrl}${request.url}` };
    const newurlWithParams = {
      urlWithParams: `${this.baseUrl}${request.urlWithParams}`,
    };
    request = Object.assign(request, newUrl, newurlWithParams);
    let dataStorage = localStorage.getItem(LocalStorageList.currentUser);
    let token = JSON.parse(dataStorage).token;

    return request.clone({
      setHeaders: { Authorization: `Bearer ${token}` },
    });
  }
}
