import { Routes, RouterModule } from "@angular/router";
import { AuthGuard } from "@app/services/Security/guard.service";
import { PaquetesMedicamentosRoutingModule } from "./paquetes-medicamentos/paquetes-medicamentos.routing";
import { PaquetesProcedimientosRoutingModule } from "./paquetes-procedimientos/paquetes-procedimientos.routing";
import { ListadoCronicosRoutingModule } from "./reportes/listado-cronico/listado-cronico.routing";
import { ListadoPrenatalRoutingModule } from "./reportes/listado-prenatal/listado-prenatal.routing";
import { ListadoRemisionesRoutingModule } from "./reportes/listado-remisiones/listado-remisiones.routing";
import { PartogramaRoutingModule } from "./partograma/partograma.routing";
//import { ListadoRemisionesRoutingModule } from "../Asistencial/Reportes/listado-remisiones/listado-remisiones.routing";




const routes: Routes = [

  //Listado Remisiones
  ListadoRemisionesRoutingModule.RoutingRemisionesRedirect,
  ListadoRemisionesRoutingModule.RoutingRemisiones,

  //listafo Cronico
  ListadoCronicosRoutingModule.RoutingCronicosRedirect,
  ListadoCronicosRoutingModule.RoutingCronicos,

  //Listado prenatal
  ListadoPrenatalRoutingModule.RoutingPrenatalRedirect,
  ListadoPrenatalRoutingModule.RoutingPrenatal,

  //Paquetes de medicamentos
  PaquetesMedicamentosRoutingModule.RoutingPaquetesMedicamentosRedirect,
  PaquetesMedicamentosRoutingModule.RoutingPaquetesMedicamentos,

  //Paquetes de procedimientos
  PaquetesProcedimientosRoutingModule.RoutingPaquetesProcedimientosRedirect,
  PaquetesProcedimientosRoutingModule.RoutingPaquetesProcedimientos,

  // Partograma
  PartogramaRoutingModule.RoutingPartograma,



];
export const HistoriaClinicasRoutes = RouterModule.forChild(routes);
