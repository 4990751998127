import { Routes, RouterModule } from "@angular/router";
import { ExamenesLaboratorioRoutingModule } from "./examenes-laboratorio/examenes-laboratorio-routing.module";
import { ParametrizacionLaboratorioClinicoRoutingModule } from "./parametrizacion-laboratorio-clinico/parametrizacion-laboratorio-clinico-routing.module";

const routes: Routes = [

  // Parametrizacion redirect
  ParametrizacionLaboratorioClinicoRoutingModule.RoutingParametrizacionLaboratorioRedirect,

  // Parametrizacion
  ParametrizacionLaboratorioClinicoRoutingModule.RoutingParametrizacionLaboratorio,

  //Examenes redirect
  ExamenesLaboratorioRoutingModule.RoutingLabExamenesRedirect,
  
  //Examenes
  ExamenesLaboratorioRoutingModule.RoutingExamenesLaboratorio,
  

];
export const LaboratorioRoutes = RouterModule.forChild(routes);
