import { Injectable, Inject } from '@angular/core';
import { HttpRequest, HttpHandler, HttpInterceptor, HttpSentEvent, HttpHeaderResponse, HttpProgressEvent, HttpResponse, HttpUserEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, BehaviorSubject, throwError, } from 'rxjs';
import { catchError, switchMap, finalize, filter, take, tap } from 'rxjs/operators';

import { UserInformation } from '../models/SaludPlus/UserInformation.model';
import { AuthenticationService } from '../services/Security/authentication.service';  
import { LocalStorageList } from './enums/enums.keys';
import { PersistenceService } from '../services/Utilities/data.persistence.service';
import { SettingsService } from '../services/Utilities/settings.service';  
import { RequestData, SPlusFormsNotifyErrorsService } from '@saludplus/forms';


@Injectable()
export class JwtInterceptor implements HttpInterceptor {

    isRefreshingToken = false;
    version:string;
    tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    baseUrl: string;
    constructor(
        private authService: AuthenticationService,
        private notifyErrorsService: SPlusFormsNotifyErrorsService, 
        private settingsService: SettingsService,
        private persistenceService: PersistenceService) { 
            this.baseUrl = this.settingsService.getBaseUrlApi;
        }

    private addTokenToRequest(request: HttpRequest<any>, token: string): HttpRequest<any> {
     
        if(request.url.includes('googleapis')){
            return request;
        }
  
        return request.clone({ setHeaders: { Authorization: `Bearer ${token}` } });
    }

    intercept(request: HttpRequest<any>, next: HttpHandler):
        Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any> | any> {              
            
            if(!request.url.includes(this.baseUrl) && !request.url.includes('googleapis')){
                const newUrl = {url: `${this.baseUrl}${request.url}`};
                const newurlWithParams = {urlWithParams: `${this.baseUrl}${request.urlWithParams}`};
                request = Object.assign(request, newUrl,newurlWithParams);
            }
            
            
        return next.handle(this.addTokenToRequest(request, this.authService.getToken()))
            .pipe(
                tap(res => {
                    if((res as HttpResponse<any>).status === 200){
                        let data = new RequestData();
                        data.Code = 200;  
                        this.notifyErrorsService.onNotify(data);
                    }

                    this.version = this.persistenceService.getData(LocalStorageList.version, true)
                }),
                
                catchError(err => {
 
                    if (err instanceof HttpErrorResponse && request.url.indexOf('/Auth/login') === -1) {

                        switch ((err as HttpErrorResponse).status) {
                            case 401:
                                if (err.url.indexOf(this.baseUrl) >= 0) {
                                    return this.handle401Error(request, next);
                                } else {
                                    return throwError(err);
                                }
                            case 0:
                            case 403:
                                //   return throwError(err);
                                return this.ErrorOnNotify(request, next, (<HttpErrorResponse>err).status);
                            case 400: 
                                    console.warn((err as HttpErrorResponse), request.url);
                               
                                // return <any>this.authService.logout();
                                return throwError(err);

                        }
                    } else {
                        return throwError(err);
                    }
                    return throwError(err);
                }));
    }

    private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
        if (!this.isRefreshingToken) {
            this.isRefreshingToken = true;

            // Reset here so that the following requests wait until the token
            // comes back from the refreshToken call.
            this.tokenSubject.next(null);

            return this.authService.refreshToken(false)
                .pipe(
                    switchMap((user: UserInformation) => {
                        if (user && user.token) {
                            this.tokenSubject.next(user.token);
                            //login call refresh 0 > 566                            
                            return next.handle(this.addTokenToRequest(request, user.token));
                        }
                        return this.authService.logout() as any;
                    }),
                    tap(evt => {

                        if (evt instanceof HttpResponse) {
                            if (evt.ok && evt.status === 200) {                                
                                const currentVersion = this.persistenceService.getData(LocalStorageList.version, true);                                
                                if (this.version) {
                                    if (currentVersion !== this.version) {
                                        this.UpdateVersion(request, next);
                                    }
                                    else
                                        return;
                                }
                                else {
                                    this.version = currentVersion;
                                }
                            }
                        }

                    }),
                    catchError(err => {
                        return this.authService.logout() as any;
                    }),
                    finalize(() => {
                        this.isRefreshingToken = false;
                    })
                );
        } else {
            this.isRefreshingToken = false;

            return this.tokenSubject
                .pipe(filter(token => token != null),
                    take(1),
                    switchMap(token => {
                        return next.handle(this.addTokenToRequest(request, token));
                    }));
        }
    }

    private ErrorOnNotify(request: HttpRequest<any>, next: HttpHandler, errorNumber) {
        let data = new RequestData();
        data.Code = errorNumber;
        this.notifyErrorsService.onNotify(data);
        return next.handle(request);
    }

    private handle403Error(request: HttpRequest<any>, next: HttpHandler) {
        let data = new RequestData();
        data.Code = 0;
        this.notifyErrorsService.onNotify(data);
        return next.handle(request);
    }
    private UpdateVersion(request: HttpRequest<any>, next: HttpHandler) {      
        let data = new RequestData();
        data.Code = 200;
        data.Data = this.version;
        data.msj = "Nueva Version Disponible"
        this.notifyErrorsService.onNotify(data);
        return next.handle(request);
    }

}
